import { gql } from "@apollo/client";

export const TextFragment = gql`
	fragment Text on Text {
		texts {
			langKey
			text
		}
	}
`;

export const ArticleFragment = gql`
	fragment Article on Article {
		type
		number
		year
		manufacturer
		location
		name {
			...Text
		}
		description {
			...Text
		}
		prices {
			id
			amount {
				...Text
			}
			price
		}
		flags {
			id
			name {
				...Text
			}
			number
			type
		}
	}
`;

export const SectionFragment = gql`
	fragment PublishedMenuCardSection on PublishedMenuCardSection {
		title {
			...Text
		}
		articles {
			...Article
		}
		settings {
			disableArticlePriceAmount
			sectionPrice
			minPersons
			wineRecommendation {
				...Article
			}
			correspondingWinesPrice
		}
	}
`;

export const MenuCardFragment = gql`
	fragment PublishedMenuCard on PublishedMenuCard {
		id
		name
		pdfPath
		meta {
			createdAt
			updatedAt
		}
		sections {
			...PublishedMenuCardSection
		}
	}
`;
