/* eslint-disable jsx-a11y/label-has-associated-control */
import { useQuery } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { GET_PUBLISHED_MENU_CARD } from "../../API/Queries/MenuCard";
import DotSpinner from "../../Components/spinner/DotSpinner";
import { PublishedMenuCard } from "../../Types/apiTypes";
import Convert from "../../Utils/Convert";
import ArticleComponent from "./Article";
import CardSettingsContext from "./CardSettingsContext";
import LocalizedText from "./LocalizedText";
import "./MenuCard.sass";

const MenuCard = () => {
	const { menuCardID } = useParams<{ menuCardID: string }>();
	const { t } = useTranslation();
	const [showIngredients, updateShowIngredients] = React.useState(false);
	const { data, loading, error } = useQuery<{ publishedMenuCard: PublishedMenuCard }>(GET_PUBLISHED_MENU_CARD, {
		variables: { menuCardID },
	});
	const menuCard = data?.publishedMenuCard;
	let errorMessage: undefined | string;
	if (error) {
		switch (error.message) {
			case "record not found":
				errorMessage = t("Die angeforderte Speisekarte konnte nicht geladen werden");
				break;
			default:
				errorMessage = error.message;
				break;
		}
	}

	return (
		<>
			{loading && (
				<div className="loading">
					<DotSpinner />
				</div>
			)}
			{!loading && (
				<div className="menu-card">
					{errorMessage || !menuCard ? (
						<div className="error">
							<p className="message">{errorMessage}</p>
						</div>
					) : (
						<CardSettingsContext.Provider value={{ showIngredients }}>
							<form className="toolbar">
								<div className="checkbox">
									<input
										id="showIngredients"
										type="checkbox"
										checked={showIngredients}
										onChange={() => updateShowIngredients(!showIngredients)}
									/>
									<label htmlFor="showIngredients">
										<span />
										{t("Inhaltsstoffe und Allegene anzeigen")}
									</label>
								</div>
								{menuCard.pdfPath && (
									<a
										href={menuCard.pdfPath}
										download={`${menuCard.name}.pdf`}
										rel="noopener noreferrer"
										target="_blank"
										className="link">
										{t("Speisekarte herunterladen")}
									</a>
								)}
							</form>
							<div className="sections">
								{menuCard.sections.map((section, i) => (
									<div
										key={i as any}
										className={section.title && section.title.texts.length > 0 ? "section" : "section no-title"}>
										<LocalizedText text={section.title} type="h2" />
										<div className="articles">
											{section.articles.map((article, ii) => (
												<ArticleComponent article={article} key={ii as any} />
											))}
										</div>

										{section.settings.sectionPrice && (
											<div className="section-price">
												{t("Prei pro Person", { price: Convert.numberToPrice(section.settings.sectionPrice) })}
											</div>
										)}
										{section.settings.minPersons && (
											<div className="min-persons">
												{t("Ab {{persons}} Personen", { persons: section.settings.minPersons })}
											</div>
										)}
										{section.settings.wineRecommendation && (
											<div className="wine-recommendation">
												<h3>{t("Unsere Weinempfehlung")}</h3>
												<ArticleComponent article={section.settings.wineRecommendation} />
											</div>
										)}
										{section.settings.correspondingWinesPrice && (
											<div className="corresponding-wines">
												<p>{t("Auf Wunsch servieren wir Ihnen zu jedem Gang ein Glas ausgesuchten Wein")}</p>
												<p className="price">{Convert.numberToPrice(section.settings.correspondingWinesPrice)}</p>
											</div>
										)}
									</div>
								))}
							</div>
						</CardSettingsContext.Provider>
					)}
				</div>
			)}
		</>
	);
};

export default MenuCard;
