import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Article, ArticleFlagTypes, ArticleTypes } from "../../Types/apiTypes";
import Convert from "../../Utils/Convert";
import CardSettingsContext from "./CardSettingsContext";
import LocalizedText from "./LocalizedText";

interface ArticleProps {
	article: Article;
}

const ArticleComponent = ({ article }: ArticleProps) => {
	const { t } = useTranslation();
	const { showIngredients } = useContext(CardSettingsContext);

	const className = () => {
		let cN = "article";
		if (article.number) cN += " with-number";
		if (!article.prices || article.prices.length === 0 || !article.prices[0].price) cN += " without-price";
		return cN;
	};
	return (
		<button className={className()}>
			{article.number && <p className="number">{article.number}</p>}
			<div>
				<p className="name">
					<span className="year">
						{article.type === ArticleTypes.Wine && article.year && <>{t("{{year}}er", { year: article.year })}</>}{" "}
					</span>
					<LocalizedText type="span" text={article.name} />
				</p>
				<LocalizedText className="description" type="p" text={article.description} />
				<p className="manufacturer">{article.manufacturer}</p>
				<p className="location">{article.location}</p>
				{showIngredients && (
					<div className="ingredients">
						{article.flags.filter((x) => x.type === ArticleFlagTypes.Additive).length > 0 && (
							<p className="title">{t("Zusatzstoffe")}:</p>
						)}
						<ul>
							{article.flags
								.filter((x) => x.type === ArticleFlagTypes.Additive)
								.map((flag) => (
									<LocalizedText className="flag" type="li" text={flag.name} />
								))}
						</ul>
						{article.flags.filter((x) => x.type === ArticleFlagTypes.Allergens).length > 0 && (
							<p className="title">{t("Allergene")}:</p>
						)}
						<ul>
							{article.flags
								.filter((x) => x.type === ArticleFlagTypes.Allergens)
								.map((flag) => (
									<LocalizedText className="flag" type="li" text={flag.name} />
								))}
						</ul>
					</div>
				)}
			</div>
			<div />
			<div className="prices">
				{article.prices.map((price, iii) => (
					<div className="price-item" key={iii as any}>
						<LocalizedText className="amount" type="p" text={price.amount} />
						{price.price && <p className="price">{Convert.numberToPrice(price.price)}</p>}
					</div>
				))}
			</div>
		</button>
	);
};

export default ArticleComponent;
