export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
};

export type ArticleUpdate = {
	type: ArticleTypes;
	number?: Maybe<Scalars["Int"]>;
	minPersonNum?: Maybe<Scalars["Int"]>;
	year?: Maybe<Scalars["Int"]>;
	location?: Maybe<Scalars["String"]>;
	manufacturer?: Maybe<Scalars["String"]>;
};

export type MenuCardSettings = {
	__typename?: "MenuCardSettings";
	showPageNum: Scalars["Boolean"];
	showItemNum: Scalars["Boolean"];
	defaultLangKey: Scalars["String"];
	defaultItemMargin: Scalars["Float"];
	langKeys: Scalars["String"];
	basisFontSize: Scalars["Float"];
	watermark?: Maybe<MenuCardSettingsWatermark>;
	divider?: Maybe<MenuCardSettingsDivider>;
	padding: Padding;
	enableEatDescription: Scalars["Boolean"];
};

export type Text = {
	__typename?: "Text";
	id: Scalars["ID"];
	style?: Maybe<TextStyle>;
	texts: Array<LocalizedText>;
};

export type InputPadding = {
	top: Scalars["Float"];
	right: Scalars["Float"];
	bottom: Scalars["Float"];
	left: Scalars["Float"];
};

export type MenuCardOverview = {
	__typename?: "MenuCardOverview";
	id: Scalars["ID"];
	name: Scalars["String"];
	description: Scalars["String"];
	template: MenuCardTemplates;
	thumbnailUrl?: Maybe<Scalars["String"]>;
	isMainMenu?: Maybe<Scalars["Boolean"]>;
	meta: Meta;
};

export type Padding = {
	__typename?: "Padding";
	top: Scalars["Float"];
	right: Scalars["Float"];
	bottom: Scalars["Float"];
	left: Scalars["Float"];
};

export type PublishMenuCardResponse = {
	__typename?: "PublishMenuCardResponse";
	imagePath: Scalars["String"];
	pdfPath: Scalars["String"];
	menuCard: PublishedMenuCard;
};

export type UpdatePage = {
	layout: PageLayouts;
	settings: UpdatePageSettings;
};

export type Position = {
	__typename?: "Position";
	left: Scalars["Float"];
	top: Scalars["Float"];
};

export type UpdateMenuCardSettingsWatermark = {
	url?: Maybe<Scalars["String"]>;
	opacity?: Maybe<Scalars["Int"]>;
	size?: Maybe<Scalars["Int"]>;
};

export type LocalizedText = {
	__typename?: "LocalizedText";
	id: Scalars["ID"];
	langKey: Scalars["String"];
	text: Scalars["String"];
	meta: Meta;
};

export type MoveResponse = {
	__typename?: "MoveResponse";
	fromParent?: Maybe<Scalars["ID"]>;
	toParent?: Maybe<Scalars["ID"]>;
	from: Scalars["Int"];
	to: Scalars["Int"];
};

export type Page = {
	__typename?: "Page";
	id: Scalars["ID"];
	index: Scalars["Int"];
	menuCardID: Scalars["ID"];
	layout: PageLayouts;
	name: Text;
	settings: PageSettings;
	articleSections: Array<ArticleSection>;
	freeTexts: Array<FreeText>;
	freeImages: Array<FreeImage>;
	meta: Meta;
};

export type CreateLocalizedText = {
	langKey: Scalars["String"];
	text: Scalars["String"];
};

export enum PageLayouts {
	CenteredBlock = "CENTERED_BLOCK",
	CenteredPriceBottom = "CENTERED_PRICE_BOTTOM",
	CenteredPriceRight = "CENTERED_PRICE_RIGHT",
	Custom = "CUSTOM",
	TwoColumnsBlock = "TWO_COLUMNS_BLOCK",
	TwoColumnsPriceBottom = "TWO_COLUMNS_PRICE_BOTTOM",
}

export type Image = {
	__typename?: "Image";
	url?: Maybe<Scalars["String"]>;
	height?: Maybe<Scalars["Float"]>;
	width?: Maybe<Scalars["Float"]>;
};

export type TextUpdate = {
	style?: Maybe<TextStyleInput>;
	texts: Array<Scalars["ID"]>;
};

export enum TextParent {
	Articles = "Articles",
	ArticleSections = "ArticleSections",
	Pages = "Pages",
}

export type ArticleFlagAssignment = {
	__typename?: "ArticleFlagAssignment";
	articleID: Scalars["ID"];
	flagID: Scalars["ID"];
};

export type SignedUrlResponse = {
	__typename?: "SignedUrlResponse";
	url: Scalars["String"];
	signedRequest: Scalars["String"];
	key: Scalars["String"];
};

export enum ImageExtensions {
	Gif = "gif",
	Jpeg = "jpeg",
	Jpg = "jpg",
	Png = "png",
	Svg = "svg",
	Tiff = "tiff",
}

export type CreateMenuCard = {
	template: MenuCardTemplates;
	name: Scalars["String"];
	description: Scalars["String"];
	isMainMenu?: Maybe<Scalars["Boolean"]>;
};

export enum ArticleFlagTypes {
	Additive = "ADDITIVE",
	Allergens = "ALLERGENS",
	DishType = "DISH_TYPE",
	Ingredient = "INGREDIENT",
}

export type MenuCard = {
	__typename?: "MenuCard";
	id: Scalars["ID"];
	name: Scalars["String"];
	description: Scalars["String"];
	template: MenuCardTemplates;
	thumbnailUrl?: Maybe<Scalars["String"]>;
	isMainMenu?: Maybe<Scalars["Boolean"]>;
	pages: Array<Page>;
	settings: MenuCardSettings;
	meta: Meta;
};

export type FreeImage = {
	__typename?: "FreeImage";
	id: Scalars["ID"];
	width: Scalars["Float"];
	height: Scalars["Float"];
	url: Scalars["String"];
	position: Position;
	meta: Meta;
};

export type ArticleFlagUpdate = {
	number?: Maybe<Scalars["Int"]>;
};

export type UpdateArticleSection = {
	position?: Maybe<PositionInput>;
	settings: UpdateArticleSectionSettings;
};

export type PublishedMenuCard = {
	__typename?: "PublishedMenuCard";
	id: Scalars["ID"];
	name: Scalars["String"];
	meta: Meta;
	pdfPath: Scalars["String"];
	pngPath: Scalars["String"];
	sections: Array<PublishedMenuCardSection>;
};

export type Meta = {
	__typename?: "Meta";
	createdAt: Scalars["Int"];
	updatedAt: Scalars["Int"];
};

export type UpdateArticleSectionSettings = {
	disableArticlePriceAmount?: Maybe<Scalars["Boolean"]>;
	showDivider?: Maybe<Scalars["Boolean"]>;
	sectionPrice?: Maybe<Scalars["Float"]>;
	minPersons?: Maybe<Scalars["Int"]>;
	maxWidth?: Maybe<Scalars["Float"]>;
	correspondingWinesPrice?: Maybe<Scalars["Float"]>;
	isItemBorderVisible?: Maybe<Scalars["Boolean"]>;
};

export type MenuCardSettingsWatermark = {
	__typename?: "MenuCardSettingsWatermark";
	url?: Maybe<Scalars["String"]>;
	opacity?: Maybe<Scalars["Int"]>;
	size?: Maybe<Scalars["Int"]>;
};

export type TextStyle = {
	__typename?: "TextStyle";
	fontSize?: Maybe<Scalars["String"]>;
	fontWeight?: Maybe<Scalars["String"]>;
	fontStyle?: Maybe<Scalars["String"]>;
	textDecoration?: Maybe<Scalars["String"]>;
	fontFamily?: Maybe<Scalars["String"]>;
};

export type ArticleFlag = {
	__typename?: "ArticleFlag";
	id: Scalars["ID"];
	name: Text;
	number?: Maybe<Scalars["Int"]>;
	type: ArticleFlagTypes;
	meta: Meta;
};

export type Mutation = {
	__typename?: "Mutation";
	_empty?: Maybe<Scalars["String"]>;
	/** Creates a new menu card with some sample content. All content is already mapped to the new menu card */
	createMenuCard: MenuCardOverview;
	/** Updates a menu card name, description or isMainMenu. You cannot change the content (e.g) pages directly. Use the provided mutations to change this data */
	updateMenuCard: MenuCardOverview;
	/** Updates some menu card settings. */
	updateMenuCardSettings: MenuCardSettings;
	/** Deletes a menu card. */
	deleteMenuCard: DeletionPayload;
	/**
	 * Adds a new menu page to a menu card. The page is empty.
	 * The index can be used to define the new index where the new page should be added
	 */
	addPage: Page;
	/** Updates the first level properties of a page like layout */
	updatePage: Page;
	/** Moves the page to a new position */
	movePage: Page;
	/** Deletes a page by it's id */
	deletePage: DeletionPayload;
	/** Updates page settings. */
	updatePageSettings: Page;
	/** Creates a localized text */
	createLocalizedText: LocalizedText;
	/** Updates a localized text for a text entry */
	updateLocalizedText: LocalizedText;
	/** Creates a new text object. Add a new text to element */
	createText: Text;
	/** Update text */
	updateText: Text;
	/** Adds a new article section to a page */
	addArticleSection: ArticleSection;
	/** Moves a article section to a new position */
	moveArticleSection: MoveResponse;
	/** Add a wine recommendation to a existing section */
	addWineRecommendation: Article;
	/** Updates article section data */
	updateArticleSection: ArticleSection;
	/** Deletes a article seciton by it's id */
	deleteArticleSection: DeletionPayload;
	/** Add new article to article section */
	addArticle: Article;
	/** Update a article */
	updateArticle: Article;
	/** Moves an article to new position */
	moveArticle: MoveResponse;
	/** Deletes an article by it's id */
	deleteArticle: DeletionPayload;
	/** Adds a new article price */
	addArticlePrice: ArticlePrice;
	/** Update an article price */
	updatePrice: ArticlePrice;
	/** Deletes an article price */
	deleteArticlePrice: DeletionPayload;
	/** Assigns an article flag to an article */
	assignArticleFlag: ArticleFlagAssignment;
	/** Deassigns an article flag from an article */
	deassignArticleFlag: DeletionPayload;
	/** Adds an article flag */
	addArticleFlag: ArticleFlag;
	/** Updates an article flag */
	updateArticleFlag: ArticleFlag;
	/** Delete an article flag */
	deleteArticleFlag: DeletionPayload;
	/** Publishes a menu card and renders its content */
	publishMenuCard: PublishMenuCardResponse;
	/** Gets a new signed url to upload a image to this path. You have to provide the extension to get a new path */
	getSignedUrl: SignedUrlResponse;
};

export type MutationCreateMenuCardArgs = {
	menuCard: CreateMenuCard;
};

export type MutationUpdateMenuCardArgs = {
	id: Scalars["ID"];
	menuCard: UpdateMenuCard;
};

export type MutationUpdateMenuCardSettingsArgs = {
	id: Scalars["ID"];
	settings: UpdateMenuCardSettings;
};

export type MutationDeleteMenuCardArgs = {
	id: Scalars["ID"];
};

export type MutationAddPageArgs = {
	menuCardId: Scalars["ID"];
	index?: Maybe<Scalars["Int"]>;
};

export type MutationUpdatePageArgs = {
	id: Scalars["ID"];
	page: UpdatePage;
};

export type MutationMovePageArgs = {
	menuCardId: Scalars["ID"];
	id: Scalars["ID"];
	index: Scalars["Int"];
};

export type MutationDeletePageArgs = {
	id: Scalars["ID"];
};

export type MutationUpdatePageSettingsArgs = {
	id: Scalars["ID"];
	settings: UpdatePageSettings;
};

export type MutationCreateLocalizedTextArgs = {
	textId: Scalars["ID"];
	text: CreateLocalizedText;
};

export type MutationUpdateLocalizedTextArgs = {
	id: Scalars["ID"];
	text: Scalars["String"];
};

export type MutationCreateTextArgs = {
	elementType: TextParent;
	elementID: Scalars["ID"];
	elementProperty: Scalars["String"];
};

export type MutationUpdateTextArgs = {
	id: Scalars["ID"];
	text: TextUpdate;
};

export type MutationAddArticleSectionArgs = {
	pageId: Scalars["ID"];
	column: Scalars["Int"];
	row: Scalars["Int"];
};

export type MutationMoveArticleSectionArgs = {
	id: Scalars["ID"];
	toPage: Scalars["ID"];
	toRow: Scalars["Int"];
	toColumn: Scalars["Int"];
};

export type MutationAddWineRecommendationArgs = {
	sectionID: Scalars["ID"];
};

export type MutationUpdateArticleSectionArgs = {
	id: Scalars["ID"];
	section: UpdateArticleSection;
};

export type MutationDeleteArticleSectionArgs = {
	id: Scalars["ID"];
};

export type MutationAddArticleArgs = {
	sectionId: Scalars["ID"];
	articleType: ArticleTypes;
	index: Scalars["Int"];
};

export type MutationUpdateArticleArgs = {
	id: Scalars["ID"];
	article: ArticleUpdate;
};

export type MutationMoveArticleArgs = {
	id: Scalars["ID"];
	fromSection: Scalars["ID"];
	toSection: Scalars["ID"];
	fromIndex: Scalars["Int"];
	toIndex: Scalars["Int"];
};

export type MutationDeleteArticleArgs = {
	id: Scalars["ID"];
};

export type MutationAddArticlePriceArgs = {
	articleID: Scalars["ID"];
};

export type MutationUpdatePriceArgs = {
	id: Scalars["ID"];
	price?: Maybe<Scalars["Float"]>;
};

export type MutationDeleteArticlePriceArgs = {
	id: Scalars["ID"];
};

export type MutationAssignArticleFlagArgs = {
	articleFlagID: Scalars["ID"];
	articleID: Scalars["ID"];
};

export type MutationDeassignArticleFlagArgs = {
	articleFlagID: Scalars["ID"];
	articleID: Scalars["ID"];
};

export type MutationAddArticleFlagArgs = {
	type: ArticleFlagTypes;
};

export type MutationUpdateArticleFlagArgs = {
	id: Scalars["ID"];
	articleFlag: ArticleFlagUpdate;
};

export type MutationDeleteArticleFlagArgs = {
	id: Scalars["ID"];
};

export type MutationPublishMenuCardArgs = {
	menuCardID: Scalars["ID"];
	htmlContent: Scalars["String"];
};

export type MutationGetSignedUrlArgs = {
	extension: ImageExtensions;
};

export type PositionInput = {
	left: Scalars["Float"];
	top: Scalars["Float"];
};

export enum MenuCardTemplates {
	SquareTwenty = "SQUARE_TWENTY",
}

export type UpdateMenuCardSettings = {
	showPageNum: Scalars["Boolean"];
	showItemNum: Scalars["Boolean"];
	basisFontSize: Scalars["Float"];
	defaultLangKey: Scalars["String"];
	defaultItemMargin: Scalars["Float"];
	langKeys: Scalars["String"];
	watermark?: Maybe<UpdateMenuCardSettingsWatermark>;
	divider?: Maybe<UpdateMenuCardSettingsDivider>;
	padding: InputPadding;
	enableEatDescription: Scalars["Boolean"];
};

export type UpdatePageSettings = {
	itemMargin?: Maybe<Scalars["Float"]>;
	sectionMargin?: Maybe<Scalars["Float"]>;
	showArticleNumbers?: Maybe<Scalars["Boolean"]>;
};

export type ArticleSection = {
	__typename?: "ArticleSection";
	id: Scalars["ID"];
	pageID: Scalars["ID"];
	title?: Maybe<Text>;
	articles: Array<Article>;
	row: Scalars["Int"];
	column: Scalars["Int"];
	position?: Maybe<Position>;
	settings: ArticleSectionSettings;
	meta: Meta;
};

export type PublishedMenuCardSection = {
	__typename?: "PublishedMenuCardSection";
	pageID: Scalars["ID"];
	title?: Maybe<Text>;
	articles: Array<Article>;
	settings: PublishedMenuCardSectionSettings;
};

export type TextStyleInput = {
	fontSize?: Maybe<Scalars["String"]>;
	fontWeight?: Maybe<Scalars["String"]>;
	fontStyle?: Maybe<Scalars["String"]>;
	textDecoration?: Maybe<Scalars["String"]>;
	fontFamily?: Maybe<Scalars["String"]>;
};

export type DeletionPayload = {
	__typename?: "DeletionPayload";
	affectedRows: Scalars["Int"];
};

export type TextCreate = {
	style?: Maybe<TextStyleInput>;
	texts: Array<CreateLocalizedText>;
};

export enum ArticleTypes {
	Drink = "DRINK",
	Eat = "EAT",
	Misc = "MISC",
	Service = "SERVICE",
	Wine = "WINE",
}

export type ArticlePrice = {
	__typename?: "ArticlePrice";
	id: Scalars["ID"];
	amount?: Maybe<Text>;
	price?: Maybe<Scalars["Float"]>;
	meta: Meta;
};

export type Article = {
	__typename?: "Article";
	id: Scalars["ID"];
	type: ArticleTypes;
	number?: Maybe<Scalars["Int"]>;
	name: Text;
	description?: Maybe<Text>;
	year?: Maybe<Scalars["Int"]>;
	location?: Maybe<Scalars["String"]>;
	manufacturer?: Maybe<Scalars["String"]>;
	prices: Array<ArticlePrice>;
	flags: Array<ArticleFlag>;
	minPersonNum?: Maybe<Scalars["Int"]>;
	isActive?: Maybe<Scalars["Boolean"]>;
	meta: Meta;
};

export type Query = {
	__typename?: "Query";
	_empty?: Maybe<Scalars["String"]>;
	/** Private endpoint to list all available menu cards on this server */
	menuCards: Array<MenuCardOverview>;
	/** Private endpoint to load a specific menu card with all information */
	menuCard?: Maybe<MenuCard>;
	/** Public endpoint to load a menu card */
	publishedMenuCard?: Maybe<PublishedMenuCard>;
	/** Endpoint to load all available article flags */
	articleFlags: Array<ArticleFlag>;
};

export type QueryMenuCardArgs = {
	id: Scalars["ID"];
};

export type QueryPublishedMenuCardArgs = {
	menuCardID: Scalars["ID"];
};

export type UpdateMenuCardSettingsDivider = {
	image?: Maybe<InputImage>;
	character?: Maybe<Scalars["String"]>;
	repeat?: Maybe<Scalars["Int"]>;
};

export type UpdateMenuCard = {
	template: MenuCardTemplates;
	name: Scalars["String"];
	description: Scalars["String"];
	isMainMenu?: Maybe<Scalars["Boolean"]>;
};

export type InputImage = {
	url?: Maybe<Scalars["String"]>;
	height?: Maybe<Scalars["Float"]>;
	width?: Maybe<Scalars["Float"]>;
};

export type ArticleSectionSettings = {
	__typename?: "ArticleSectionSettings";
	disableArticlePriceAmount?: Maybe<Scalars["Boolean"]>;
	showDivider?: Maybe<Scalars["Boolean"]>;
	sectionPrice?: Maybe<Scalars["Float"]>;
	minPersons?: Maybe<Scalars["Int"]>;
	maxWidth?: Maybe<Scalars["Float"]>;
	wineRecommendation?: Maybe<Article>;
	correspondingWinesPrice?: Maybe<Scalars["Float"]>;
	isItemBorderVisible?: Maybe<Scalars["Boolean"]>;
};

export type PageSettings = {
	__typename?: "PageSettings";
	itemMargin?: Maybe<Scalars["Float"]>;
	sectionMargin?: Maybe<Scalars["Float"]>;
	showArticleNumbers?: Maybe<Scalars["Boolean"]>;
};

export type MenuCardSettingsDivider = {
	__typename?: "MenuCardSettingsDivider";
	image?: Maybe<Image>;
	character?: Maybe<Scalars["String"]>;
	repeat?: Maybe<Scalars["Int"]>;
};

export type FreeText = {
	__typename?: "FreeText";
	id: Scalars["ID"];
	width: Scalars["Float"];
	height: Scalars["Float"];
	text: Text;
	position: Position;
	meta: Meta;
};

export type PublishedMenuCardSectionSettings = {
	__typename?: "PublishedMenuCardSectionSettings";
	disableArticlePriceAmount?: Maybe<Scalars["Boolean"]>;
	sectionPrice?: Maybe<Scalars["Float"]>;
	minPersons?: Maybe<Scalars["Int"]>;
	wineRecommendation?: Maybe<Article>;
	correspondingWinesPrice?: Maybe<Scalars["Float"]>;
};
