import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		// we init with resources
		resources: {
			de: {
				translations: {
					"Die angeforderte Speisekarte konnte nicht geladen werden": "Die angeforderte Speisekarte konnte nicht geladen werden",
					"Prei pro Person": "Preis pro Person {{price}}",
					"Ab {{persons}} Personen": "Ab {{persons}} Personen",
					"Unsere Weinempfehlung": "Unsere Weinempfehlung",
					"Auf Wunsch servieren wir Ihnen zu jedem Gang ein Glas ausgesuchten Wein":
						"Auf Wunsch servieren wir Ihnen zu jedem Gang ein Glas ausgesuchten Wein",
					"{{year}}er": "{{year}}er",
					"Inhaltsstoffe und Allegene anzeigen": "Inhaltsstoffe und Allegene anzeigen",
					"Speisekarte herunterladen": "Speisekarte herunterladen",
					Zusatzstoffe: "Zusatzstoffe",
					Allergene: "Allergene",
				},
			},
			en: {
				translations: {
					"Die angeforderte Speisekarte konnte nicht geladen werden": "The requested menu could not be loaded",
					"Prei pro Person": "Price per person {{price}}",
					"Ab {{persons}} Personen": "For {{persons}} or more people",
					"Unsere Weinempfehlung": "Our wine recommendation",
					"Auf Wunsch servieren wir Ihnen zu jedem Gang ein Glas ausgesuchten Wein":
						"On request we serve a glass of selected wine with each course",
					"{{year}}er": "{{year}}",
					"Inhaltsstoffe und Allegene anzeigen": "Show ingredients and allegens",
					"Speisekarte herunterladen": "Download menu",
					Zusatzstoffe: "Ingredients",
					Allergene: "Allegens",
				},
			},
			fr: {
				translations: {
					"Die angeforderte Speisekarte konnte nicht geladen werden": "Le menu demandé n'a pas pu être chargé",
					"Prei pro Person": "Par personne {{price}}",
					"Ab {{persons}} Personen": "Pour {{persons}} ou plus de personnes",
					"Unsere Weinempfehlung": "Notre recommandation sur le vin",
					"Auf Wunsch servieren wir Ihnen zu jedem Gang ein Glas ausgesuchten Wein":
						"Sur demande, nous servons un verre de vin sélectionné avec chaque plat",
					"{{year}}er": "{{year}}",
					"Inhaltsstoffe und Allegene anzeigen": "Montrer les ingrédients et les allégens",
					"Speisekarte herunterladen": "Menu de téléchargement",
					Zusatzstoffe: "Additifs",
					Allergene: "Allergènes",
				},
			},
		},
		fallbackLng: "en",
		debug: true,
		ns: ["translations"],
		defaultNS: "translations",
		keySeparator: false, // we use content as keys
		interpolation: {
			escapeValue: false, // not needed for react!!
			formatSeparator: ",",
		},
		react: {
			useSuspense: true,
		},
	});
export default i18n;
