/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";
import { ArticleFragment, MenuCardFragment, SectionFragment, TextFragment } from "../Fragments";

export const GET_PUBLISHED_MENU_CARD = gql`
	${TextFragment}
	${ArticleFragment}
	${SectionFragment}
	${MenuCardFragment}
	query publishedMenuCard($menuCardID: ID!) {
		publishedMenuCard(menuCardID: $menuCardID) {
			...PublishedMenuCard
		}
	}
`;
