import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../../Types/apiTypes";

interface LocalizedTextProps {
	type?: "p" | "div" | "span" | "li" | "h1" | "h2" | "h3" | "h4";
	text?: Text | null;
	className?: string;
	style?: React.CSSProperties;
}

const LocalizedText = ({ type, text, className, style }: LocalizedTextProps) => {
	const { i18n } = useTranslation();
	const langKey = i18n.language.toUpperCase();

	let cN = className ?? "";
	const defaultLang = "DE";
	let displayText: string | undefined;

	if (text) {
		const { texts } = text;
		displayText = texts?.find((x) => x.langKey === langKey)?.text;
		if (!displayText) {
			displayText = texts?.find((x) => x.langKey === defaultLang)?.text;
			if (displayText) {
				cN += " default-locale-fallback";
			} else {
				cN += " no-text";
			}
		}
	}

	if (!displayText) return <></>;
	const s = {
		...style,
	};

	const props: any = {};
	if (type !== undefined) {
		props.style = s;
		props.className = cN;
	}
	return React.createElement(type ?? React.Fragment, props, displayText);
};

export default LocalizedText;
