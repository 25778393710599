import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import APOLLO_CLIENT from "./API/ApolloClient";
import i18n from "./Internalization/i18n";
import reportWebVitals from "./reportWebVitals";
import MenuCard from "./Widgets/MenuCard/MenuCard";
import "normalize.css";
import "./Styles/default.sass";

declare global {
	interface Window {
		_env: any;
	}
}

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<ApolloProvider client={APOLLO_CLIENT}>
				<div className="widget">
					<Router>
						<Switch>
							<Route path="/menu-card/:menuCardID">
								<MenuCard />
							</Route>
						</Switch>
					</Router>
				</div>
			</ApolloProvider>
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
